import React from "react";

function SubscriptionBanner(props) {
    const onActionClick = (event) => {
        // Open subscription popup
        props.onSubscriptionPopupStateChange(true);
    }

    return (
        <div className={"relative text-denim-700 w-full"}>
            <div style={{aspectRatio: '151 / 37'}} className={"subscribe subscribe-article-banner bg-subscribe-floating-mobile bg-cover"} onClick={onActionClick}>
                <div className={"flex items-end justify-center py-1 h-full"}>
                    <button className={"px-6 bg-yellow-400 rounded-lg font-bold text-lg font-sans"}>JOIN OUR FREE NEWSLETTER</button>
                </div>
            </div>
        </div>
    );
}

export default SubscriptionBanner
